<script>
  // ...
</script>

<style global>:global(.context-menu-body) {
  display: flex;
  align-items: center;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
</style>

<div class="context-menu-body">
  <slot></slot>
</div>