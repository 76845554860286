<script>
  import {createEventDispatcher} from "svelte";
  import IconChevronLeft from "../Icons/IconChevronLeft.svelte";
  import IconCross from "../Icons/IconCross.svelte";

  export let title;
  export let back = false;

  const dispatch = createEventDispatcher();

  const methods = {
    emit: {
      back: () => dispatch("back"),
      close: () => dispatch("close"),
    },
  };
</script>

<style global>:global(.context-menu-header) {
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  width: 100%;
  height: auto;
  border-bottom-width: 1px;
  border-color: #e2e8f0;
  background-color: #fff;
}

:global(.context-menu-header) :global(h3) {
  margin: 0;
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 500;
  text-align: center;
  color: #a0aec0;
}

:global(.context-menu-header) :global(svg) {
  cursor: pointer;
  color: #a0aec0;
}

:global(.context-menu-header) :global(svg:hover) {
  color: #4a5568;
}
</style>

<div class="context-menu-header">
  {#if back}
    <IconChevronLeft on:click={methods.emit.back} />
  {/if}
  <h3>{title}</h3>
  <IconCross on:click={methods.emit.close} />
</div>