<script>
  import {createEventDispatcher} from "svelte";
  import IconChevronRight from "../Icons/IconChevronRight.svelte";

  export let title;
  export let icon;
  export let hasSubMenu = false;
  export let style = '';

  const dispatch = createEventDispatcher();

  const methods = {
    emit: {
      click: () => dispatch("click"),
    },
  };
</script>

<style global>:global(.context-menu-item) {
  display: flex;
  align-items: center;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
}

:global(.context-menu-item) :global(.label) {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  width: 100%;
  padding-right: 0.5rem;
}

:global(.context-menu-item) :global(.label) :global(span) {
  font-size: 1rem;
  line-height: 1.25rem;
  color: #4a5568;
}

:global(.context-menu-item:hover) :global(.label) :global(span) {
  color: #1a202c;
}

:global(.context-menu-item) :global(svg) {
  color: #a0aec0;
}

:global(.context-menu-item:hover) :global(svg) {
  color: #4a5568;
}
</style>

<div class="context-menu-item" 
  style={style}
  on:click={methods.emit.click}
>
  <div class="label text-blue-500">
    <svelte:component this={icon} />
    <span>{title}</span>
  </div>
  {#if hasSubMenu}
    <IconChevronRight />
  {/if}
</div>