<script>
	import MenuHeader from './ContextMenu/MenuHeader.svelte';
	import MenuBody from './ContextMenu/MenuBody.svelte';
	import { isMode } from './utils/helper.js';

	// Important notes:
	// --
	//   !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	// * !!IF YOU CHANGE HOW TEXT ANNOTATIONS WORK!!
	//   !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	// * make sure you test the Dynamic Fields in Templates flow in NotaryLive. See OrderApiV2CreateOrder.php -> populateDynamicFieldsIfRelevant() for where the dynamic field gets converted into a simple text field
	// 
	// * There was a known bug where typing a string of text and then resizing the font upwards was cutting off the right side of the text. That was happening for both Windows and Mac devices. The first fix we applied seemed to only fix the issue on Mac devices. The second fix seems to fix it on both Windows and Mac devices, and is based on the setTimeout() in onFontSizeChange. Make sure to test on both platforms. With BrowserStack, you can use an email client to check the PDF, in case BrowserStack prevents you from accessing the local filesystem. (RA, Sept 2022)
	// 
	// * For now, we are only allowing Times New Roman as the font, because other fonts when 'flattened' (ie., Courier) are invisible in the browser, but show up again in the final PDF. (RA, Aug 2022)
	// --
	import { Fonts } from "./utils/prepareAssets.js";
	import IconMinus from "./Icons/IconMinus.svelte";
	import IconPlus from "./Icons/IconPlus.svelte";
	import IconUserCircle from "./Icons/IconUserCircle.svelte";
	import IconTextInput from "./Icons/IconTextInput.svelte";
	import { onMount, createEventDispatcher } from "svelte";
	import { pannable } from "./utils/pannable.js";
	import { tapout } from "./utils/tapout.js";
	import { timeout, containsEmoji } from "./utils/helper.js";

	export let simpleModalButtonText;
	export let simpleModalMessage;
	export let simpleModalTitle;
	export let simpleModalVisible;
	export let createdFromContextMenu;
	export let colorSelection = 'black';
	export let currentActivePage;
	export let disableEditing;
	export let editorMode;
	export let fontFamily;
	export let id;
	export let isAnnotation;
	export let isDynamicField;
	export let isFocusedProp = false;
	export let lineHeight;
	export let lines;
	export let lockedInPlace;
	export let pageIndex;
	export let pageScale = 1;
	export let participants = [];
	export let size;
	export let text;
	export let urlParams;
	export let x;
	export let y;

	const Families = Object.keys(Fonts);
	const dispatch = createEventDispatcher();

	let _color = "black";
	let _dialogXPosition = 0;
	let _fontFamily = fontFamily;
	let _lineHeight = lineHeight;
	let _size = size;
	let _colorSelection = _color;

	let clickedInDialog = false;
	let clickedInText = false;
	let deleteIconSpacing = 20;
	let dx = 0;
	let dy = 0;
	let editable;
	let expandDialog = false;
	let isFocused = isFocusedProp;
	let operation = "";
	let startX;
	let startY;
	let showParticipantMenu = false;

	let convertOptionElement;

	let mainMenuRect = null;
	let convertOptionRect = null;

	if (window.innerWidth < 768) {
		deleteIconSpacing = 30;
	}

	// ----------------------------
	// - Functions (Alphabetized) -
	// ----------------------------

	function clickedOnDeleteBtn(event) {
		return (
			event.detail.target.src
			&& event.detail.target.src.includes('/delete.svg')
		);
	}

	function clickedOnExpandDialog(event) {
		return (
			event.detail.target.src
			&& event.detail.target.src.includes('/edit-white.svg')
		);
	}

	function closeDialog() {
		expandDialog = false;
		clickedInDialog = false;
		clickedInText = false;
		isFocused = false;
		operation = '';
		showParticipantMenu = false;
		
		dispatch("stoppedEditingText", {});
	}

	function decreaseFontSize() {
		if (_size <= 4) return;
		if (_size >= 48) return _size = _size - 4;
		_size = _size - 2;
	}

	function extractLines() {
		const nodes = editable.childNodes;
		const outputLines = [];
		let lineText = "";

		for (let index = 0; index < nodes.length; index++) {
			const node = nodes[index];

			if (node.nodeName === "BR") {
				outputLines.push(lineText);
				lineText = "";
			} else {
				lineText += node.textContent;
			}
		}

		outputLines.push(lineText);

		return sanitizeOutputLines(outputLines);
	}

	function formatDynamicFieldName() {
		if (editable) {
			editable.innerText = formatDynamicFieldNameString(
				editable.innerText
			);
		}
		
		lines[0] = formatDynamicFieldNameString(lines[0]);
	}

	function formatDynamicFieldNameString(input) {
		  input = input.toLowerCase();

		  // Replace spaces with underscores
		  input = input.replace(/ /g, '_');

		  // Replace all special characters except underscores with an empty string
		  input = input.replace(/[^a-z0-9_]/g, '');

		  return input;
	}

	function handleClick(event) {
		if (lockedInPlace) return;
		clickedInText = true;
		isFocused = true;
	}

	function handlePannableClick(event) {
		if (isMode('ron_order') && isAnnotation && text === 'Signing date') {
			dispatch('notifyAnnotationNotFillableYet', {});
			return;
		}

		if (isAnnotation) {
			dispatch('click', {
				el: editable,
				id: id,
				pageIndex: pageIndex,
			});
		}
	}

	function handleClickedInDialog(event) {
		clickedInDialog = true;
	}

	function handlePanEnd(event) {
		if (dx === 0 && dy === 0) {
			handlePannableClick(event);
			return editable.focus();
		}

		dispatch("update", {
			eventType: "dragEnd",
			height: editable.offsetHeight,
			x: x + dx,
			y: y + dy
		});

		dx = 0;
		dy = 0;
		operation = "";
		isFocused = false;
	}

	function handlePanMove(event) {
		if (lockedInPlace) {
			return;
		}

		dx = (event.detail.x - startX) / pageScale;
		dy = (event.detail.y - startY) / pageScale;
		
		if ((Math.abs(dx) > 5 || Math.abs(dy) > 5) && showParticipantMenu) {
			showParticipantMenu = false;
		}
	}

	function handleExpandDialog(event) {
		expandDialog = true;
	}

	function handlePanStart(event) {
		startX = event.detail.x;
		startY = event.detail.y;
		operation = "move";
		
		if (showParticipantMenu) {
			showParticipantMenu = false;
		}
		
		expandDialog = false;
	}

	function handleTapOutsideDialog(event) {
		if (clickedOnExpandDialog(event)) {
			return;
		}

		if (clickedOnDeleteBtn(event)) {
			onTapout();
			onBlur(event);
			return;
		}

		// Check if click is inside participant submenu
		if (event.detail.target.closest('#participant-submenu')) {
			return;
		}

		clickedInDialog = false;
		if (clickedInText) {return}

		if (event.detail.node === event.detail.target) return;
		if (operation !== 'edit') isFocused = false;

		onTapoutDialog();
		expandDialog = false;
		
		showParticipantMenu = false;
	}

	function handleTapOutsideField(event) {
		if (clickedOnDeleteBtn(event)) {
			onTapout();
			onBlur(event);
			return;
		}

		clickedInText = false;

		if (clickedInDialog 
			|| clickedOnExpandDialog(event)
		) {
			return;
		}

		onTapout();
		onBlur(event);			
		isFocused = false;
	}

	function increaseFontSize() {
		if (_size >= 128) return;
		if (_size >= 48) return _size = _size + 4;

		_size = _size + 2;
	}

	function onBlur(event) {		
		if (operation !== "edit") return;
		
		if (isDynamicField) {
			formatDynamicFieldName();
		}
		
		editable.blur();
		sanitize();
		let lines = extractLines();

		dispatch("update", {
			height: editable.offsetHeight,
			lines: lines,
			width: editable.clientWidth
		});

		operation = "";
		// expandDialog = false;
		updateDialogXPosition();
		dispatch("stoppedEditingText", {});
	}

	function onChangeFont() {
		dispatch("selectFont", {
			name: _fontFamily
		});
	}

	function onDelete() {
		dispatch("delete");
	}

	function onEnterPressed(event) {
		event.preventDefault();
		const childNodes = Array.from(editable.childNodes);
		const selection = window.getSelection();
		const focusNode = selection.focusNode;
		const focusOffset = selection.focusOffset;

		// the caret is at an empty line
		if (focusNode === editable) {
			editable.insertBefore(
					document.createElement("br"),
					childNodes[focusOffset]
			);
		} else if (focusNode instanceof HTMLBRElement) {
			editable.insertBefore(document.createElement("br"), focusNode);
		}
		// the caret is at a text line but not end
		else if (focusNode.textContent.length !== focusOffset) {
			document.execCommand("insertHTML", false, "<br>");
			// the carat is at the end of a text line
		} else {
			let br = focusNode.nextSibling;
			if (br) {
				editable.insertBefore(document.createElement("br"), br);
			} else {
				br = editable.appendChild(document.createElement("br"));
				br = editable.appendChild(document.createElement("br"));
			}
			// set selection to new line
			selection.collapse(br, 0);
		}
	}

	function onFocus(event) {
		if (disableEditing) {
			return;
		}

		updateDialogXPosition();
		isFocused = true;

		operation = "edit";
		dispatch("editingText", {});
	}

	function onFontSizeChange() {
		let newSize = parseInt(_size);

		if (typeof newSize !== "number") {
			return _size;
		}

		_size = newSize;

		if (editable) {
			setTimeout(() => {
				dispatch("update", {
					height: editable.offsetHeight,
					lines: extractLines(),
					size: _size,
					width: editable.clientWidth,
				});
			}, 100);
		}
	}

	function onFontColorChange() {
		if (editable) {
			setTextColor();
			setTimeout(() => {
				dispatch("update", {
					color: _color,
					height: editable.offsetHeight,
					lines: extractLines(),
					width: editable.clientWidth,
				});
			}, 100);
		}
	}

	function onBeforeInput(event) {
		if (containsEmoji(event.data)) {
			event.preventDefault();
			event.target.innerHTML = 'New text field...';
			simpleModalButtonText = "Okay"
			simpleModalMessage = "Emojis cannot be used."
			simpleModalTitle = 'Error'
			simpleModalVisible = true
		}
	}

	function onKeydown(event) {
		setTextColor();

		if (textIsPlaceholder()) {
			lines[0] = '';
			editable.innerText = '';
		}

		switch (event.key) {
			case "Enter":
				if (isDynamicField) return onBlur(event);
				return onEnterPressed(event);
			case "Escape":
				return onBlur(event);
		}

		setTimeout(() => {
			dispatch("update", {
				height: editable.offsetHeight,
				lines: extractLines(),
				width: editable.clientWidth
			});
			updateDialogXPosition();
		}, 500);
	}

	function onPageChange() {
		onBlur();
	}

	async function onPaste(e) {
		// get text only
		const pastedText = e.clipboardData.getData("text");
		document.execCommand("insertHTML", false, pastedText);
		// await tick() is not enough
		await timeout();
		sanitize();
	}

	function onTapout() {
		dispatch('tapout', {});
	}

	function onTapoutDialog() {
		dispatch('tapoutDialog', {});
	}

	// To autofocus on an item, use 'editable.focus()'
	// --
	function render() {
		for (let i = 0; i < lines.length; i++) {
			const lineText = lines[i];
			editable.appendChild(document.createTextNode(lineText));

			if (i < lines.length - 1) {
				editable.appendChild(document.createElement("br"));
			}
		}

		setTextColor();
		updateDialogXPosition();

		_size = parseInt(_size);

		dispatch("update", {
			fontFamily: _fontFamily,
			height: editable.offsetHeight,
			lineHeight: _lineHeight,
			lines: lines,
			size: _size,
			width: editable.clientWidth,
		});
	}

	function sanitize() {
		if (!editable) return;
		let weirdNode;

		while (
			(weirdNode = Array.from(editable.childNodes).find(
					node => !["#text", "BR"].includes(node.nodeName)
			))
		) {
			editable.removeChild(weirdNode);
		}
	}

	function sanitizeOutputLines(array) {
		const output = array;

		if (array.length && array[array.length - 1] === '') {
			output.pop();
		}

		return output;
	}

	function setTextColor() {
		// if/else enforces annotation colors
		if (textIsPlaceholder()) {
			if (isAnnotation) {
				return _color = "#FEB2B2"; // faint red
			} else {
				switch (_colorSelection) {
					case 'blue':
						return _color = '#90CDF4';
					default:
						return _color = "#808080";
				}
			}
		}

		// if/else enforces annotation colors
		if (isAnnotation) {
			return _color = "#F56565"; // red, but slightly faint
		} else {
			switch (_colorSelection) {
				case 'blue':
					return _color = '#4299E1';
				default:
					return _color = '#000000';
			}
		}
	}

	// If you change this, make sure to change PDF.js's writeTextToPage as well (RA, Jan 2023)
	// We are going with this solution instead of using CSS, because the CSS we used didn't seem to work universally on our current set of modern browsers. We had used the following CSS:
	// :global(.placeholder::before) {
	// but that didn't work on Mac Brave browser and I believe it didn't work on other browsers as well. This was the more foolproof solution. (RA, Jan 2023)
	// --
	function textIsPlaceholder() {
		return (
			lines[0] === 'New text field...'
			|| lines[0] === 'New annotation...'
			|| lines[0] === 'field_name'
		);
	}

	function updateDialogXPosition() {
		_dialogXPosition = x + dy;

		if (editable) {
			_dialogXPosition += editable.clientWidth + 8;
		} else {
			_dialogXPosition += 68; // Set based on the text width
		}
	}


	function toggleParticipantMenu() {
		if (!showParticipantMenu) {
			const menuElement = document.querySelector('#context-menu');
			const optionElement = convertOptionElement;
			
			if (menuElement && optionElement) {
				mainMenuRect = menuElement.getBoundingClientRect();
				convertOptionRect = optionElement.getBoundingClientRect();
			}
		}
		
		showParticipantMenu = !showParticipantMenu;
	}

	function convertToTextInput(participant) {
		showParticipantMenu = false;
		
		// Prepare the data for conversion
		const textInputData = {
			id,
			type: 'textbox',
			participant,
			text: lines.join('\n'),
			position: { x, y },
			size: _size,
			fontFamily: _fontFamily,
			color: _color,
			lineHeight: _lineHeight,
			pageIndex,
		};
		
		dispatch("convertToTextInput", textInputData);
		closeDialog();
	}

	function backToMainMenu() {
		showParticipantMenu = false;
	}

	// ---------------------------
	// - Lifecycle Event Binding -
	// ---------------------------

	onMount(render);

	// ---------------------------------------------
	// - Watchers to react to changes in variables -
	// ---------------------------------------------

	$:_size, onFontSizeChange();
	$:_colorSelection, onFontColorChange();
	$:currentActivePage, onPageChange();
	$:dx, updateDialogXPosition();
	$:x, updateDialogXPosition();

</script>

<style>.editing {
  pointer-events: none;
}

.font-family {
  display: block;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  height: 1.5rem;
  width: 100%;
  background-color: #fff;
  padding-left: 0.5rem;
  padding-right: 2rem;
  border-radius: 0.125rem;
  line-height: 1.25;
}

.color-palette {
  height: 2rem;
}

.color-palette .color-palette-item {
  position: relative;
  width: 1.25rem;
  height: 1.25rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 0.25rem;
  border-width: 2px;
  border-color: #fff;
  margin: 0;
}

.color-palette .color-palette-item:active,
.color-palette .color-palette-item:checked {
  border-color: transparent;
}

.color-palette .color-palette-item:disabled {
  cursor: not-allowed;
  opacity: .5;
}

.color-palette .color-palette-item:checked::after {
  content: '';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 9999px;
  background-color: #fff;
}

.no-controls {
  -moz-appearance: textfield;
}

.no-controls::-webkit-outer-spin-button,
.no-controls::-webkit-inner-spin-button {
  -webkit-appearance: none;
          appearance: none;
  margin: 0;
}

</style>

<svelte:options immutable={true} />

<div
	on:pointerdown={event => handleClick(event)}
	use:tapout
	on:tapout={(event) => handleTapOutsideField(event)}
	class="absolute left-0 top-0 select-none"
	style="transform: translate({x + dx}px, {y + dy}px);"
	id={'text-field-' + pageIndex + '-' + id}
>
	<div
		class:opacity-100={isFocused}
		class:opacity-15={!isFocused}
		class:border-red-500={isAnnotation}
		class:border-gray-500={!isAnnotation}
		class="absolute border-2 rounded-lg pointer-events-none"
		style="top: -2px; right: -4px; bottom: -2px; left: -4px;"
	></div>

	<div
		use:pannable
		on:panstart={handlePanStart}
		on:panmove={handlePanMove}
		on:panend={handlePanEnd}
		class="absolute w-full h-full"
		class:cursor-pointer={lockedInPlace}
		class:cursor-grab={!lockedInPlace && !operation}
		class:cursor-grabbing={!lockedInPlace && operation === 'move'}
		class:editing={!disableEditing && operation === 'edit'}
	/>

	<div
		bind:this={editable}
		on:focus={onFocus}
		on:keydown={onKeydown}
		on:paste|preventDefault={onPaste}
		on:beforeinput={onBeforeInput}
		contenteditable="{!disableEditing}"
		spellcheck="false"
		class="outline-none whitespace-no-wrap"
		style="
		color: {_color};
		font-size: {_size}px;
		font-family: '{_fontFamily}', serif;
		line-height: {_lineHeight};
		-webkit-user-select: text;
		"
	/>
</div>

{#if isFocused}
	{#if !disableEditing || createdFromContextMenu}
		<div
			class="absolute left-0 top-0 select-none"
			style="transform: translate({x + dx - deleteIconSpacing}px, {y + dy - deleteIconSpacing}px);"
		>
			<div
				on:click={onDelete}
				class="bg-white cursor-pointer h-8 md:h-5 md:w-5 opacity-50 rounded-full w-8"
			>
				<img class="h-full w-full" src="/delete.svg" alt="delete object" />
			</div>
		</div>
	{/if}
{/if}

{#if isFocused && operation !== 'move' && !disableEditing}
	<div
		class="absolute select-none"
		style="transform: translate({_dialogXPosition}px, {y + dy}px);"
	>
		{#if expandDialog}
			<div
				id="context-menu"
				class="context-menu-container"
				on:pointerup={handleClickedInDialog}
				use:tapout
				on:tapout={(event) => {handleTapOutsideDialog(event)}}
			>
				<div class="context-menu">
					{#if !showParticipantMenu}
						<!-- Main menu -->
						<div class="context-menu-page">
							<MenuHeader
								title="Edit text..."
								on:close={closeDialog}
							/>

							<MenuBody>
								<div class="flex flex-col w-full">
									<div class="flex gap-2 pb-2">
										<div class="flex items-center color-gray-500">
											<IconMinus on:click={decreaseFontSize}/>
											<input
												bind:value={_size}
												on:change={onFontSizeChange}
												type="number"
												class="no-controls w-full p-1 border border-gray-300 rounded-lg text-center"
											/>
											<IconPlus on:click={increaseFontSize}/>
										</div>

										<div class="flex-shrink-0 p-1 border border-gray-300 rounded-lg color-palette">
											{#if isAnnotation}
												<input type="radio"
													class="bg-red-500 color-palette-item"
													checked
												/>
											{:else}
												<input type="radio"
													name="color"
													bind:group={_colorSelection}
													value="black"
													class="bg-black color-palette-item"
													checked
												/>
												<!-- <input type="radio"
														 name="color"
														 bind:group={_colorSelection}
														 value="blue"
														 class="bg-blue-500 color-palette-item"> -->
											{/if}
										</div>
									</div>

									<select
										bind:value={_fontFamily}
										on:change={onChangeFont}
										class="w-full p-1 border border-gray-300 rounded-lg"
									>
										{#each Families as family}
											<option value={family}>{family}</option>
										{/each}
									</select>
									{#if isMode('esign_preorder', 'preorder', 'cna_preorder')}
										<div class="menu-section mt-2">
											<div class="text-sm text-gray-600 mb-2 font-medium">Actions:</div>
											<button
												bind:this={convertOptionElement}
												on:click={toggleParticipantMenu} 
												class="w-full text-left px-3 py-2 flex items-center justify-between rounded transition-colors duration-150 hover:bg-gray-100 focus:outline-none"
											>
												<div class="flex items-center">
													<div class="w-5 h-5 flex-shrink-0 flex items-center justify-center mr-3">
														<IconTextInput />
													</div>
													<span class="text-sm text-gray-700">Convert to Text Input</span>
												</div>
												<svg class="h-4 w-4 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
													<path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
												</svg>
											</button>
										</div>
									{/if}
								</div>
							</MenuBody>
						</div>
					{:else}
						<!-- Participant selection menu -->
						<div class="context-menu-page">
							<MenuHeader
								title="Participants"
								showBackButton={true}
								on:back={backToMainMenu}
								on:close={closeDialog}
							/>
							
							<MenuBody>
								<div class="menu-section">
									<div class="flex flex-col w-full max-h-64 overflow-y-auto">
										{#if participants && participants.length > 0}
											{#each participants as participant, i}
												{#if participant.email && participant.full_name}
													<button
														class="w-full text-left px-3 py-2 flex items-center rounded transition-colors duration-150 hover:bg-gray-100"
														on:click={() => convertToTextInput(participant)}
													>
														<IconUserCircle size={20} />
															<span 
																class="text-sm text-gray-700"
																style="margin-left: 15px;"
															>{participant.full_name} 
															</span>
													</button>
												{/if}
											{/each}
										{:else}
											<div class="p-2 text-gray-500">No participants available</div>   	<!-- In Reality it should never land here. -->
										{/if}
									</div>
								</div>
							</MenuBody>
						</div>
					{/if}
				</div>
			</div>
		{:else}
			<div class="bg-gray-700 border-0 cursor-pointer h-7 md:h-5 md:w-5 opacity-75 rounded-lg w-7">
				<img
					on:pointerup={handleExpandDialog}
					alt="edit text"
					class="h-full w-full"
					src="/edit-white.svg"
				/>
			</div>
		{/if}
	</div>
{/if}